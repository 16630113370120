<script>
export default {
  props: {
    clickHandler: {
      type: Function,
      default: () => null,
    },
  },
}
</script>

<template functional>
  <v-col sm="12" md="6" lg="4" xl="3" cols="12">
    <div :class="$style.addAdministrator" @click="props.clickHandler">
      <v-icon :class="$style.icon" x-large>person_add</v-icon>
      <h4>{{ parent.$t('ADD_ADMINISTRATOR') }}</h4>
    </div>
  </v-col>
</template>

<style lang="scss" module>
@import '@design';
.addAdministrator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 466px;
  height: 160px;
  margin: 10px 27px 20px 0;
  color: $dark;
  cursor: pointer;
  border: 2px dashed $grey;
  border-radius: 10px;
  outline: none;
  &:hover,
  &:focus {
    color: $primary;
    border: 2px solid $grey;
    box-shadow: 0 0 20px $user-card-shadow;
  }

  .icon {
    color: inherit;
  }

  h4 {
    margin: 10px 0 0 0;
    font-weight: bold;
    transition-duration: 0.3s;
  }
}
</style>
